@import '../../../../../node_modules/@unifii/library/styles/cards';

.uc-card-mixin() {
    display: flex;
    flex-direction: column;

    background: white;
    box-shadow: @box-shadow;

    &[class*='--centered'] {
        text-align: center;

        .fieldset-label {
            flex: 1;
            text-align: left;
        }

        .fieldset-value {
            flex: 1;
            text-align: right;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    >uf-panel {
        flex: 1;
    }

    >.header {
        padding: 0 @gutter;

        *[class*='uf-action'] {
            float: right;

            &:first-of-type {
                margin-right: -@gutter;
            }
        }
    }

    .content {
        padding: 0 @gutter;
    }
}

//-------------------------------------
//             Default Card

.uc-card,
.uc-card--centered {
    .uc-card-mixin();

    overflow: hidden;
    border-radius: @border-radius;
}

//-------------------------------------
//             links

a.uc-card,
a.uc-card--centered {
    cursor: pointer;

    text-decoration: none;

    transition: all .1s ease-in-out;

    &:hover {
        box-shadow: @box-shadow-lg;
    }
}

.uc-card--centered {
    .uc-card-title {
        justify-content: center;
    }
}

//-------------------------------------
//             Card title

// Used for centering and keeping labels consistant

.uc-card-title {
    display: flex;
    min-height: @gutter*3.5rem;
    align-items: center;
    padding: 0 @gutter;

    >* {
        margin: 0;
    }
}

//-------------------------------------
//             Card actions

// Can be used at top and bottom of card

.uc-card-actions {
    float: right;

    >* {
        float: right;
    }

    >.uf-action {
        margin-top: -@space-3;
        margin-right: -@space-3;
    }

    >*[class*='uf-button'] {
        margin-left: @space-3;

        &:first-child {
            margin-right: @space-3;
        }
    }
}

// Align heading if actions first child

.col-1of1 {

    &>.uc-card-actions+h1,
    &>.uc-card-actions+h2,
    &>.uc-card-actions+h3,
    &>.uc-card-actions+h4,
    &>.uc-card-actions+h5,
    &>.uc-card-actions+h6 {
        margin-top: 0.5rem;
    }
}


//-------------------------------------
//             Form Card

// was originally content card

.uc-form-card {
    .uc-card-mixin();

    position: relative;

    &:before {
        content: '';
        display: block;

        border-top: @space-2 solid;
        border-top-color: @brand-accent;
    }

    uf-progress {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}


//-------------------------------------
//             Card Groups

.uc-card-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    grid-gap: @gutter*2;
    align-items: stretch;
}


@media (max-width: @mobile-screen) {

    .uc-card-title>* {
        font-size: @font-size-sm;
        line-height: @line-height-sm;
    }

}




*[class*='uc-form-card'] {
    uf-panel {
        // TODO remove when uf-panel is fixed across all of console
        position: relative !important;
        margin: 0;
    }
}

.uc-card-list {
    grid-template-columns: repeat(auto-fill, 224px);
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    *[class*='uc-card'] {

        h1 {
            font-size: 2.5rem;
            line-height: 3.125rem;
        }

        h2 {
            font-size: 2rem;
            line-height: 2.5rem;
        }

        h3 {
            font-size: 1.125rem;
            line-height: 1.5rem;
        }

        h4 {
            font-size: 1rem;
            line-height: 1.25rem;
        }

        h5 {
            font-size: 1rem;
            line-height: 1.25rem;
        }

        h6 {
            font-size: 1rem;
            line-height: 1.25rem;
        }

    }
}